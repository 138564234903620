import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import CategoryForm from "../../forms/categoryForm/categoryForm";
import * as categoryService from "../../services/internal/categories";
import {forms} from "@atttomyx/shared-utils";
import {CATEGORY_STATUS_DRAFT} from "../../constants";

const freshData = () => {
    return {
        status: CATEGORY_STATUS_DRAFT,
        title: null,
        hidden: false,
        valid: false,
    }
};

const AddCategoryDialog = (props) => {
    const { snackbar, onCancel, onSave } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(saved.title + " saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const category = {
            status: data.status,
            title: data.title,
            imageUrl: "https://placehold.co/100x100",
        };

        categoryService.createCategory(category, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="add-category-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add Category
        </ClosableDialogTitle>
        <DialogContent className="category">
            {saving ?
                <CircularProgress size="40px"/> :
                <CategoryForm
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AddCategoryDialog;
