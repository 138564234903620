export const loadMyAccount = function(success, failure) {
    success({});
};

export const saveMyAccount = function(account, success, failure) {
    success({});
};

export const removeCard = function(success, failure) {
    success();
};

export const chargeNow = function(success, failure) {
    success({});
};

export const uploadImage = function(accountId, logo, success, failure) {
    success(null);
};
