import React from "react";
import {MenuItem, TextField, Typography} from "@mui/material";
import {cloudinary, forms, objects, strings} from "@atttomyx/shared-utils";
import {CATEGORY_STATUS_ACTIVE, CATEGORY_STATUS_DRAFT} from "../../constants";
import "./categoryForm.css";

const CategoryForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        modified.valid = strings.isNotBlank(modified.title)
            && strings.isNotBlank(modified.status);

        onSync(modified);
    }

    return <div className="category-form">
        <Typography variant="h5">
            Category
        </Typography>
        {data.imageUrl ?
            <div>
                <img
                    className="image"
                    alt={data.title}
                    src={data.imageUrl ? cloudinary.shrink(data.imageUrl) : "https://placehold.co/1024x1024"}
                />
            </div> : null}
        <div className="field top-margin">
            <TextField label="Title" required={true}
                       type="text"
                       value={forms.sanitizeValue(data.title)}
                       onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField label="Status" required={true}
                       select={true}
                       value={forms.sanitizeValue(data.status)}
                       onChange={(event) => onChange("status", event.target.value)}
            >
                <MenuItem value={CATEGORY_STATUS_ACTIVE}>Active</MenuItem>
                <MenuItem value={CATEGORY_STATUS_DRAFT}>Draft</MenuItem>
            </TextField>
        </div>
    </div>
}

export default CategoryForm;
