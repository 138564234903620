import React, {useEffect} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {useBootstrap, useEntities, useFilters, useProgress, useRenderer} from "@atttomyx/shared-hooks";
import {usePushEvent} from "@atttomyx/react-hooks";
import {
    AboutPage,
    ChangePasswordPage,
    ForgotPasswordPage,
    HomePage,
    LoginPage,
    NotFoundPage,
    ProfilePage,
    PublicHeader,
    Splash,
    UserSettingsPage,
    WelcomePage
} from "@atttomyx/react-components";
import Footer from "./components/footer/footer";
import AppsPage from "./pages/appsPage/appsPage";
import AccountsPage from "./pages/accountsPage/accountsPage";
import UsersPage from "./pages/usersPage/usersPage";
import RobotsPage from "./pages/robotsPage/robotsPage";
import AdsPage from "./pages/adsPage/adsPage";
import CategoriesPage from "./pages/categoriesPage/categoriesPage";
import EditCategoryPage from "./pages/editCategoryPage/editCategoryPage";
import BillingsPage from "./pages/billingsPage/billingsPage";
import EditBillingPage from "./pages/editBillingPage/editBillingPage";
import AddAppPage from "./pages/addAppPage/addAppPage";
import EditAppPage from "./pages/editAppPage/editAppPage";
import AddAccountPage from "./pages/addAccountPage/addAccountPage";
import EditAccountPage from "./pages/editAccountPage/editAccountPage";
import AddUserPage from "./pages/addUserPage/addUserPage";
import EditUserPage from "./pages/editUserPage/editUserPage";
import UserSettingsForm from "./forms/userSettingsForm/userSettingsForm";
import * as appService from "./services/app";
import * as authService from "./services/auth";
import * as internalAppService from "./services/internal/apps";
import * as internalAccountService from "./services/internal/accounts";
import * as internalUserService from "./services/internal/users";
import * as internalRobotService from "./services/internal/robots";
import * as internalAdService from "./services/internal/ads";
import * as internalCategoryService from "./services/internal/categories";
import * as internalBillingService from "./services/internal/billings";
import * as internalChargeService from "./services/internal/charges";
import * as internalPaymentService from "./services/internal/payments";
import * as internalLoginService from "./services/internal/logins";
import * as myAccountService from "./services/noopMyAccount";
import * as notificationService from "./services/noopNotifications";
import * as profileService from "./services/profile";
import {sorting, strings} from "@atttomyx/shared-utils";
import {branding as brandingUtils, firebase as firebaseUtils, router} from "@atttomyx/react-utils";
import {renderUser, sortByNameAndAlias} from "./utils/users";
import {sortByNextBillDueAndReason} from "./utils/charges";
import {themes, time} from "@atttomyx/shared-constants";
import {
    APP_NAME,
    APP_TAG_LINE,
    FIREBASE_CONFIG,
    PAGE_ABOUT,
    PAGE_ACCOUNT,
    PAGE_ACCOUNTS,
    PAGE_ADS,
    PAGE_APP,
    PAGE_APPS,
    PAGE_BILLING,
    PAGE_BILLINGS,
    PAGE_CATEGORIES,
    PAGE_CATEGORY,
    PAGE_HOME,
    PAGE_LOGIN,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_PROFILE,
    PAGE_RECOVERY,
    PAGE_ROBOTS,
    PAGE_USER,
    PAGE_USERS,
    PAGE_WELCOME,
    PRIVATE_PAGES_EXACT,
    PRIVATE_PAGES_STARTS_WITH,
    PUBLIC_PAGES_EXACT,
    PUBLIC_PAGES_STARTS_WITH,
    ROLE_TO_HOME_PAGE
} from "./constants";
import {
    ACCOUNT_FILTERS_DATA,
    APP_FILTERS,
    BILLING_FILTERS,
    BILLING_FILTERS_DATA,
    CATEGORY_FILTERS,
    getAccountFilters,
    getAdFilters,
    getRobotFilters,
    USER_FILTERS
} from "./filters/filters";
import icon from "./icon.png";

const App = props => {
    const {dimensions, snackbar, history, onThemeChange} = props;
    const apps = useEntities(internalAppService.listApps, snackbar.setError, "apps", sorting.sortByTitle);
    const accounts = useEntities(internalAccountService.listAccounts, snackbar.setError, "accounts", sorting.sortByName);
    const users = useEntities(internalUserService.listUsers, snackbar.setError, "users", sortByNameAndAlias);
    const robots = useEntities(internalRobotService.listRobots, snackbar.setError, "robots", sorting.sortByName);
    const logins = useEntities(internalLoginService.listLogins, snackbar.setError, "logins", sorting.sortByUpdatedDesc);
    const ads = useEntities(internalAdService.listAds, snackbar.setError, "ads", sorting.sortByUpdatedDesc);
    const categories = useEntities(internalCategoryService.listCategories, snackbar.setError, "categories", sorting.sortByTitle);
    const billings = useEntities(internalBillingService.listBillings, snackbar.setError, "billings", sorting.sortByName);
    const charges = useEntities(internalChargeService.listCharges, snackbar.setError, "charges", sortByNextBillDueAndReason);
    const payments = useEntities(internalPaymentService.listPayments, snackbar.setError, "payments", sorting.sortByCreatedDesc);

    const appRenderer = useRenderer(apps, (app) => app.title);
    const accountRenderer = useRenderer(accounts, (account) => account.name);
    const userRenderer = useRenderer(users, renderUser);
    const billingRenderer = useRenderer(billings, (billing) => billing.name);

    const appFilters = useFilters(APP_FILTERS);
    const accountFilters = useFilters(getAccountFilters(appRenderer, billingRenderer), ACCOUNT_FILTERS_DATA);
    const userFilters = useFilters(USER_FILTERS);
    const robotFilters = useFilters(getRobotFilters(appRenderer));
    const adFilters = useFilters(getAdFilters(appRenderer));
    const categoryFilters = useFilters(CATEGORY_FILTERS);
    const billingFilters = useFilters(BILLING_FILTERS, BILLING_FILTERS_DATA);

    const onLogout = () => {
        authService.cancelAllRequests("logged out");
        authService.clearAuthToken();

        progress.clear();
        bootstrap.clear();
        apps.clear();
        accounts.clear();
        users.clear();
        robots.clear();
        logins.clear();
        ads.clear();
        categories.clear();
        billings.clear();
        charges.clear();
        payments.clear();

        appFilters.clear();
        accountFilters.clear();
        userFilters.clear();
        robotFilters.clear();
        adFilters.clear();
        categoryFilters.clear();
        billingFilters.clear();

        router.redirectToInitialPage(history, PUBLIC_PAGES_EXACT, PUBLIC_PAGES_STARTS_WITH, PAGE_LOGIN);
    };

    const bootstrapSuccess = () => {
        const temporaryPassword = authService.getTemporaryPassword();

        if (strings.isNotBlank(temporaryPassword)) {
            router.redirectTo(history, PAGE_PASSWORD);

        } else {
            router.redirectToInitialPage(history, PRIVATE_PAGES_EXACT, PRIVATE_PAGES_STARTS_WITH, PAGE_HOME);
        }

        if (bootstrap.user.roles.apps || bootstrap.user.roles.accounts
            || bootstrap.user.roles.robots) {

            apps.refresh();

        } else {
            apps.empty();
        }

        if (bootstrap.user.roles.accounts || bootstrap.user.roles.users) {
            accounts.refresh();

        } else {
            accounts.empty();
        }

        if (bootstrap.user.roles.users) {
            users.refresh();
            logins.refresh();

        } else {
            users.empty();
            logins.empty();
        }

        if (bootstrap.user.roles.robots) {
            robots.refresh();

        } else {
            robots.empty();
        }

        if (bootstrap.user.roles.ads) {
            ads.refresh();

        } else {
            ads.empty();
        }

        if (bootstrap.user.roles.reveal) {
            categories.refresh();

        } else {
            categories.empty();
        }

        if (bootstrap.user.roles.billing) {
            billings.refresh();
            charges.refresh();
            payments.refresh();

        } else {
            billings.empty();
            charges.empty();
            payments.empty();
        }
    };

    const bootstrapFailure = (err) => {
        snackbar.setError(err);
        onLogout();
    };

    const bootstrap = useBootstrap(authService, myAccountService, profileService, notificationService,
        bootstrapSuccess, bootstrapFailure);

    const progress = useProgress(bootstrap,
        [apps, accounts, users, robots, logins, ads, categories, billings, charges, payments],
        [],
        time.MILLISECONDS_IN_SECOND);

    const event = usePushEvent(bootstrap);

    const onLogin = (accounts) => {
        progress.refresh();
        bootstrap.refresh(accounts);
    };

    useEffect(() => {
        if (event && event.type) {
            const type = event.type;
            const data = event.payload || {};
            const actor = data.actor || {};

            console.table(type, data);

            if (bootstrap.user.id !== actor.userId) {
                // todo: implement these events in accounts-api
                switch (type) {
                    case "app_saved":
                    case "app_deleted":
                        apps.refresh();
                        break;
                    case "account_saved":
                    case "account_deleted":
                        accounts.refresh();
                        break;
                    case "user_saved":
                    case "user_deleted":
                        users.refresh();
                        break;
                    case "robot_saved":
                    case "robot_deleted":
                        robots.refresh();
                        break;
                    case "login_saved":
                    case "login_deleted":
                        logins.refresh();
                        break;
                    case "ad_saved":
                    case "ad_deleted":
                        ads.refresh();
                        break;
                    case "category_saved":
                    case "category_deleted":
                        categories.refresh();
                        break;
                    case "billing_saved":
                    case "billing_deleted":
                        billings.refresh();
                        break;
                    case "charge_saved":
                    case "charge_deleted":
                        charges.refresh();
                        break;
                    case "payment_saved":
                    case "payment_deleted":
                        payments.refresh();
                        break;
                    default:
                        break;
                }
            }
        }
    }, [event]);

    useEffect(() => {
        const settings = bootstrap.user ? bootstrap.user.settings || {} : {};
        const theme = settings.theme || themes.LIGHT;
        const branding = brandingUtils.getBranding(theme);

        onThemeChange(theme, branding);
    }, [bootstrap.user]);

    useEffect(() => {
        appService.ensureOnLatestVersion(false, null);
        authService.setupAxiosInterceptors(onLogout);
        firebaseUtils.initFirebase(FIREBASE_CONFIG);

        if (authService.loggedIn()) {
            onLogin();

        } else {
            onLogout();
        }
    }, []);

    return <div className="app">
        {progress.loading || progress.paused ?
            <Splash
                percent={progress.percent}
            /> : bootstrap.user ?
                <>
                    <div className="content">
                        <Switch>
                            <Route exact path="/">
                                <Redirect to={PAGE_HOME}/>
                            </Route>
                            <Route path={PAGE_HOME}>
                                <HomePage
                                    user={bootstrap.user}
                                    roleToHomePage={ROLE_TO_HOME_PAGE}
                                    onRedirect={(page) => router.redirectTo(history, page)}
                                />
                            </Route>
                            {bootstrap.user.roles.apps ?
                                <Route path={PAGE_APPS}>
                                    <AppsPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        apps={apps.entities}
                                        filters={appFilters}
                                        onSave={apps.onEntitySaved}
                                        onDelete={apps.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.apps ?
                                <Route exact path={PAGE_APP}>
                                    <AddAppPage
                                        snackbar={snackbar}
                                        onSave={(app) => {
                                            apps.onEntitySaved(app);
                                            router.redirectTo(history, `${PAGE_APP}/${app.id}`);
                                        }}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.apps ?
                                <Route
                                    path={`${PAGE_APP}/:id`}
                                    render={(props) =>
                                        <EditAppPage
                                            {...props}
                                            snackbar={snackbar}
                                            apps={apps.entities}
                                            filters={appFilters}
                                            onSave={apps.onEntitySaved}
                                            onPrev={(appId) => router.redirectTo(history, `${PAGE_APP}/${appId}`)}
                                            onNext={(appId) => router.redirectTo(history, `${PAGE_APP}/${appId}`)}
                                        />}
                                /> : null}
                            {bootstrap.user.roles.billing ?
                                <Route path={PAGE_BILLINGS}>
                                    <BillingsPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        billings={billings.entities}
                                        charges={charges.entities}
                                        filters={billingFilters}
                                        userRenderer={userRenderer}
                                        onSave={billings.onEntitySaved}
                                        onDelete={billings.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.billing ?
                                <Route
                                    path={`${PAGE_BILLING}/:id`}
                                    render={(props) =>
                                        <EditBillingPage
                                            {...props}
                                            snackbar={snackbar}
                                            dimensions={dimensions}
                                            accounts={accounts.entities}
                                            accountRenderer={accountRenderer}
                                            users={users.entities}
                                            billings={billings.entities}
                                            charges={charges.entities}
                                            payments={payments.entities}
                                            filters={billingFilters}
                                            onSaveBilling={billings.onEntitySaved}
                                            onSaveCharge={charges.onEntitySaved}
                                            onDeleteCharge={charges.onEntityDeleted}
                                            onSavePayment={payments.onEntitySaved}
                                            onSaveUser={users.onEntitySaved}
                                            onPrev={(billingId) => router.redirectTo(history, `${PAGE_BILLING}/${billingId}`)}
                                            onNext={(billingId) => router.redirectTo(history, `${PAGE_BILLING}/${billingId}`)}
                                        />}
                                /> : null}
                            {bootstrap.user.roles.accounts ?
                                <Route path={PAGE_ACCOUNTS}>
                                    <AccountsPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        apps={apps.entities}
                                        appRenderer={appRenderer}
                                        billingRenderer={billingRenderer}
                                        accounts={accounts.entities}
                                        filters={accountFilters}
                                        onDelete={accounts.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.accounts ?
                                <Route exact path={PAGE_ACCOUNT}>
                                    <AddAccountPage
                                        snackbar={snackbar}
                                        apps={apps.entities}
                                        appRenderer={appRenderer}
                                        billingRenderer={billingRenderer}
                                        onSaveAccount={(account) => {
                                            accounts.onEntitySaved(account);
                                            router.redirectTo(history, `${PAGE_ACCOUNT}/${account.id}`);
                                        }}
                                        onSaveBilling={billings.onEntitySaved}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.accounts ?
                                <Route
                                    path={`${PAGE_ACCOUNT}/:id`}
                                    render={(props) =>
                                        <EditAccountPage
                                            {...props}
                                            snackbar={snackbar}
                                            apps={apps.entities}
                                            appRenderer={appRenderer}
                                            billingRenderer={billingRenderer}
                                            accounts={accounts.entities}
                                            filters={accountFilters}
                                            onSaveAccount={accounts.onEntitySaved}
                                            onSaveBilling={billings.onEntitySaved}
                                            onPrev={(accountId) => router.redirectTo(history, `${PAGE_ACCOUNT}/${accountId}`)}
                                            onNext={(accountId) => router.redirectTo(history, `${PAGE_ACCOUNT}/${accountId}`)}
                                        />}
                                /> : null}
                            {bootstrap.user.roles.users ?
                                <Route path={PAGE_USERS}>
                                    <UsersPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        user={bootstrap.user}
                                        apps={apps.entities}
                                        accounts={accounts.entities}
                                        accountRenderer={accountRenderer}
                                        users={users.entities}
                                        logins={logins.entities}
                                        filters={userFilters}
                                        onSave={users.onEntitySaved}
                                        onDelete={users.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.users ?
                                <Route
                                    path={`${PAGE_USER}/:accountId/:userId`}
                                    render={(props) =>
                                        <EditUserPage
                                            {...props}
                                            snackbar={snackbar}
                                            accounts={accounts.entities}
                                            user={bootstrap.user}
                                            users={users.entities}
                                            filters={userFilters}
                                            onSave={users.onEntitySaved}
                                            onPrev={(accountId, userId) => router.redirectTo(history, `${PAGE_USER}/${accountId}/${userId}`)}
                                            onNext={(accountId, userId) => router.redirectTo(history, `${PAGE_USER}/${accountId}/${userId}`)}
                                        />}
                                /> : null}
                            {bootstrap.user.roles.users ?
                                <Route
                                    path={`${PAGE_USER}/:accountId`}
                                    render={(props) => <AddUserPage
                                        {...props}
                                        snackbar={snackbar}
                                        accounts={accounts.entities}
                                        onSave={(accountId, user) => {
                                            users.onEntitySaved(user);
                                            router.redirectTo(history, `${PAGE_USER}/${accountId}/${user.id}`);
                                        }}
                                    />}
                                /> : null}
                            {bootstrap.user.roles.robots ?
                                <Route path={PAGE_ROBOTS}>
                                    <RobotsPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        apps={apps.entities}
                                        appRenderer={appRenderer}
                                        robots={robots.entities}
                                        logins={logins.entities}
                                        filters={robotFilters}
                                        onSave={robots.onEntitySaved}
                                        onDelete={robots.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.ads ?
                                <Route path={PAGE_ADS}>
                                    <AdsPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        apps={apps.entities}
                                        appRenderer={appRenderer}
                                        ads={ads.entities}
                                        filters={adFilters}
                                        onSave={ads.onEntitySaved}
                                        onDelete={ads.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.reveal ?
                                <Route path={PAGE_CATEGORIES}>
                                    <CategoriesPage
                                        snackbar={snackbar}
                                        dimensions={dimensions}
                                        categories={categories.entities}
                                        filters={categoryFilters}
                                        onSave={categories.onEntitySaved}
                                        onDelete={categories.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.reveal ?
                                <Route
                                    path={`${PAGE_CATEGORY}/:id`}
                                    render={(props) =>
                                        <EditCategoryPage
                                            {...props}
                                            snackbar={snackbar}
                                            dimensions={dimensions}
                                            categories={categories.entities}
                                            filters={categoryFilters}
                                            onSave={categories.onEntitySaved}
                                            onPrev={(categoryId) => router.redirectTo(history, PAGE_CATEGORY + "/" + categoryId)}
                                            onNext={(categoryId) => router.redirectTo(history, PAGE_CATEGORY + "/" + categoryId)}
                                        />}
                                /> : null}
                            <Route path={PAGE_PROFILE}>
                                <ProfilePage
                                    snackbar={snackbar}
                                    user={bootstrap.user}
                                    onSave={bootstrap.syncProfile}
                                    profileService={profileService}
                                    showImage={true}
                                />
                            </Route>
                            <Route path={PAGE_OPTIONS}>
                                <UserSettingsPage
                                    snackbar={snackbar}
                                    user={bootstrap.user}
                                    onSaveProfile={bootstrap.syncProfile}
                                    onSaveNotifications={bootstrap.syncNotifications}
                                    profileService={profileService}
                                    notificationService={notificationService}
                                    settingsForm={UserSettingsForm}
                                />
                            </Route>
                            <Route path={PAGE_PASSWORD}>
                                <ChangePasswordPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onChanged={() => router.redirectTo(history, PAGE_HOME)}
                                />
                            </Route>
                            <Route path={PAGE_ABOUT}>
                                <AboutPage
                                    src={icon}
                                    appName={APP_NAME}
                                    appDescription={APP_TAG_LINE}
                                    appService={appService}
                                    user={bootstrap.user}
                                />
                            </Route>
                            <Route>
                                <NotFoundPage/>
                            </Route>
                        </Switch>
                    </div>
                    <Footer
                        user={bootstrap.user}
                        onLogout={onLogout}
                    />
                </> :
                <>
                    <PublicHeader appName={APP_NAME} src={icon}/>
                    <div className="content">
                        <Switch>
                            <Route path={PAGE_LOGIN}>
                                <LoginPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onForgotPassword={() => router.redirectTo(history, PAGE_RECOVERY)}
                                    onLogin={onLogin}
                                />
                            </Route>
                            <Route path={PAGE_RECOVERY}>
                                <ForgotPasswordPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onBackToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                    onLogin={onLogin}
                                    appName={APP_NAME}
                                />
                            </Route>
                            <Route path={PAGE_WELCOME}>
                                <WelcomePage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                    onLogin={onLogin}
                                />
                            </Route>
                        </Switch>
                    </div>
                </>}
    </div>
}

export default withRouter(App);
